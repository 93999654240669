import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Display from './components/Display'
import Controller from './components/Controller'
const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/controller">
          <Controller />
        </Route>
        <Route exact path="*">
          <Display />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default App
