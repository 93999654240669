import { db } from '../firebase'
import React, { useState } from 'react'
import styled from 'styled-components'

export default (props) => {
  const [point, setPoint] = useState(0)
  db.collection('points')
    .doc(props.id)
    .onSnapshot((doc) => {
      const data = doc.data()
      setPoint(data.point)
    })
  return (
    <PeopleCard>
      <PeopleCardInner>
        <PeopleCardInnerContent>
          <PeopleCardPeople>
            <img src={`/images/people${props.number}.png`} alt="" />
          </PeopleCardPeople>
          <PeopleCardInfo>
            <PeopleCardName dangerouslySetInnerHTML={{ __html: props.name }} />
            <PeopleCardLifework>{props.lifework}</PeopleCardLifework>
          </PeopleCardInfo>
          <PeopleCardPoint>
            <PeopleCardPointNumber>{point}</PeopleCardPointNumber>
            <PeopleCardPointText>PT</PeopleCardPointText>
          </PeopleCardPoint>
        </PeopleCardInnerContent>
        <PeopleCardInnerBackground>
          <img src={`/images/number${props.number}.png`} alt="" />
        </PeopleCardInnerBackground>
      </PeopleCardInner>
      <PeopleCardBackground>
        <img src={`/images/people${props.number}_bg.png`} alt="" />
      </PeopleCardBackground>
    </PeopleCard>
  )
}

const PeopleCard = styled.div`
  position: relative;
  width: calc(100vw / 3);
  height: calc(100vh / 2);
  padding: 40px;
  &:nth-child(2n - 1) {
    background: linear-gradient(#bdd6c4, #526b8a);
  }
  &:nth-child(2n) {
    background: linear-gradient(#526b8a, #bdd6c4);
  }
`

const PeopleCardInner = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.16);
  overflow: hidden;
`

const PeopleCardInnerContent = styled.div`
  position: relative;
  z-index: 3;
  width: 100%;
  height: 100%;
`

const PeopleCardPeople = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`

const PeopleCardInfo = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
`

const PeopleCardName = styled.div`
  font-family: 'Audiowide', cursive;
  font-size: 2.5vw;
  line-height: 1;
  color: #fff;
  mix-blend-mode: overlay;
  text-align: right;
  span {
    font-size: 2vw;
  }
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #fff;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`

const PeopleCardLifework = styled.div`
  font-family: 'Audiowide', cursive;
  font-size: 20px;
  text-align: right;
  line-height: 1;
  color: #415369;
  mix-blend-mode: overlay;
`

const PeopleCardPoint = styled.div`
  position: absolute;
  bottom: 30px;
  right: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 10px 20px;
  width: 100%;
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border: solid #b9d2c2 1px;
    mix-blend-mode: overlay;
  }
`

const PeopleCardPointNumber = styled.div`
  font-family: 'Audiowide', cursive;
  color: #e5ffee;
  font-size: 100px;
  line-height: 1;
`

const PeopleCardPointText = styled.div`
  font-family: 'Audiowide', cursive;
  color: #96c6a3;
  font-size: 20px;
  line-height: 1;
`

const PeopleCardBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const PeopleCardInnerBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(#bdd6c4, #526b8a);
  backdrop-filter: blur(30px);
  opacity: 0.4;
`
