import React from 'react'
import PeopleCardContoller from './PeopleCardContoller'
import PeopleCardLayout from './PeopleCardLayout'
import { CHIAKI, GIMA, KENTO, NAMU, NOBU, SHU } from '../constants'

export default () => {
  return (
    <PeopleCardLayout>
      <PeopleCardContoller
        id={KENTO}
        number="01"
        name="KENTO<br/>ONISHI"
        lifework="Editor / Writer"
      />
      <PeopleCardContoller
        id={NOBU}
        number="02"
        name="NOBU<br/>OYAMA"
        lifework="Planner"
      />
      <PeopleCardContoller
        id={NAMU}
        number="03"
        name="NAMU"
        lifework="Illustrator"
      />
      <PeopleCardContoller
        id={CHIAKI}
        number="04"
        name="CHIAKI<br/>UEHIRA"
        lifework="Programmer"
      />
      <PeopleCardContoller
        id={GIMA}
        number="05"
        name="GIMA☆<br/>KENTA"
        lifework="I WAS BORN"
      />
      <PeopleCardContoller
        id={SHU}
        number="06"
        name="SHU<br/><span>YOSHIMORI</span>"
        lifework="Designer"
      />
    </PeopleCardLayout>
  )
}
