import React from 'react'
import styled from 'styled-components'

const Element = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export default (props) => {
  return <Element>{props.children}</Element>
}
