import firebase, { db } from '../firebase'
import React from 'react'
import styled from 'styled-components'

export default (props) => {
  const onClickLinkButton = () => {
    db.collection('points')
      .doc(props.id)
      .update({
        point: firebase.firestore.FieldValue.increment(1),
      })
  }
  return (
    <PeopleCardController>
      <PeopleCardControllerInner>
        <PeopleCardControllerInnerContent>
          <PeopleCardControllerPeople>
            <img src={`/images/people${props.number}.png`} alt="" />
          </PeopleCardControllerPeople>
          <PeopleCardControllerInfo>
            <PeopleCardControllerName
              dangerouslySetInnerHTML={{ __html: props.name }}
            />
            <PeopleCardControllerLifework>
              {props.lifework}
            </PeopleCardControllerLifework>
          </PeopleCardControllerInfo>
          <PeopleCardControllerButton onClick={onClickLinkButton}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="29.25"
              height="28.125"
              viewBox="0 0 29.25 28.125"
            >
              <path
                d="M24.75,3.938h-.07A8,8,0,0,0,18,7.594a8,8,0,0,0-6.68-3.656h-.07a7.949,7.949,0,0,0-7.875,7.945,17.115,17.115,0,0,0,3.361,9.33C10.969,27,18,32.063,18,32.063S25.031,27,29.264,21.213a17.115,17.115,0,0,0,3.361-9.33A7.949,7.949,0,0,0,24.75,3.938Zm2.925,16.116A53.929,53.929,0,0,1,18,29.587a54.01,54.01,0,0,1-9.675-9.541,15.169,15.169,0,0,1-2.981-8.163,5.966,5.966,0,0,1,5.92-5.97h.063a5.9,5.9,0,0,1,2.89.759,6.144,6.144,0,0,1,2.137,2,1.975,1.975,0,0,0,3.3,0,6.205,6.205,0,0,1,2.138-2,5.9,5.9,0,0,1,2.89-.759h.063a5.966,5.966,0,0,1,5.92,5.97A15.361,15.361,0,0,1,27.675,20.053Z"
                transform="translate(-3.375 -3.938)"
                fill="#00ffe2"
              />
            </svg>
          </PeopleCardControllerButton>
        </PeopleCardControllerInnerContent>
        <PeopleCardControllerInnerBackground>
          <img src={`/images/number${props.number}.png`} alt="" />
        </PeopleCardControllerInnerBackground>
      </PeopleCardControllerInner>
      <PeopleCardControllerBackground>
        <img src={`/images/people${props.number}_bg.png`} alt="" />
      </PeopleCardControllerBackground>
    </PeopleCardController>
  )
}

const PeopleCardController = styled.div`
  position: relative;
  width: calc(100vw / 3);
  height: calc(100vh / 2);
  padding: 40px;
  &:nth-child(2n - 1) {
    background: linear-gradient(#bdd6c4, #526b8a);
  }
  &:nth-child(2n) {
    background: linear-gradient(#526b8a, #bdd6c4);
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 80vw;
    padding: 24px;
  }
`

const PeopleCardControllerInner = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.16);
  overflow: hidden;
`

const PeopleCardControllerInnerContent = styled.div`
  position: relative;
  z-index: 3;
  width: 100%;
  height: 100%;
`

const PeopleCardControllerPeople = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`

const PeopleCardControllerInfo = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
`

const PeopleCardControllerName = styled.div`
  font-family: 'Audiowide', cursive;
  font-size: 2.5vw;
  line-height: 1;
  color: #fff;
  mix-blend-mode: overlay;
  text-align: right;
  @media screen and (max-width: 768px) {
    font-size: 7vw;
  }
  span {
    font-size: 2vw;
    @media screen and (max-width: 768px) {
      font-size: 5vw;
    }
  }
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #fff;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`

const PeopleCardControllerLifework = styled.div`
  font-family: 'Audiowide', cursive;
  font-size: 20px;
  text-align: right;
  line-height: 1;
  color: #415369;
  mix-blend-mode: overlay;
  @media screen and (max-width: 768px) {
    font-size: 3vw;
  }
`

const PeopleCardControllerBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const PeopleCardControllerInnerBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(#bdd6c4, #526b8a);
  backdrop-filter: blur(30px);
  opacity: 0.4;
`

const PeopleCardControllerButton = styled.button`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  appearance: none;
  background-color: rgba(0, 255, 226, 0.3);
  padding: 12px;
  width: calc(100% - 20px * 2);
  outline: none;
  border-radius: 12px;
  border: none;
`
