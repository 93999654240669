import React from 'react'
import PeopleCard from './PeopleCard'
import PeopleCardLayout from './PeopleCardLayout'
import { CHIAKI, GIMA, KENTO, NAMU, NOBU, SHU } from '../constants'

export default () => {
  document.addEventListener(
    'keypress',
    (e) => {
      if (e.keyCode === 13) {
        toggleFullScreen()
      }
    },
    false
  )
  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen()
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      }
    }
  }
  return (
    <PeopleCardLayout>
      <PeopleCard
        id={KENTO}
        number="01"
        name="KENTO<br/>ONISHI"
        lifework="Editor / Writer"
      />
      <PeopleCard
        id={NOBU}
        number="02"
        name="NOBU<br/>OYAMA"
        lifework="Planner"
      />
      <PeopleCard id={NAMU} number="03" name="NAMU" lifework="Illustrator" />
      <PeopleCard
        id={CHIAKI}
        number="04"
        name="CHIAKI<br/>UEHIRA"
        lifework="Programmer"
      />
      <PeopleCard
        id={GIMA}
        number="05"
        name="GIMA☆<br/>KENTA"
        lifework="I WAS BORN"
      />
      <PeopleCard
        id={SHU}
        number="06"
        name="SHU<br/><span>YOSHIMORI</span>"
        lifework="Designer"
      />
    </PeopleCardLayout>
  )
}
