import React from 'react'
import { render } from 'react-dom'
import App from './App'
import './base.css'
import './firebase'
import { register } from './serviceWorker'

render(<App />, window.document.getElementById('app'))

register()
