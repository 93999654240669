import firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyCtyPJS_hAaJAmah3TPa6Gh9rnP9hpZn8U',
  authDomain: 'voting-system-sample.firebaseapp.com',
  databaseURL: 'https://voting-system-sample.firebaseio.com',
  projectId: 'voting-system-sample',
  storageBucket: 'voting-system-sample.appspot.com',
  messagingSenderId: '908323840897',
  appId: '1:908323840897:web:09847eb3d9b48f340b7e80',
  measurementId: 'G-JBTQ1DB0GW',
}

firebase.initializeApp(firebaseConfig)

export default firebase

export const db = firebase.firestore()
